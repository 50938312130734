import React from "react";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import Header from "../components/Header";
import Mission from "../components/Mission";
import Info from "../components/Info";
import LeistungenTeaser from "../components/LeistungenTeaser";
import './index.css';


const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Physiotherapie Praxis Tim Winthagen</title>
        <meta name='description' content='Homepage der Physiotherapie Praxis Tim Winthagen mit Infos rund um Ihre Behandlung'/>
        <meta name='keywords' content='Physiotherapie, Krankengymnastik, Manuelle Lymphdrainage, Fußreflexzonenmassage, Wärmetherapie, Kältetherapie, Medizinische Massagetherapie, Neurologische Behandlung, Bobath Erwachsene, Faszienbehandlung, Elektrotherapie, Ultraschall, Flossing, Gerätetraining, Hausbesuche, Düren'/>
        <meta property='og:title' content='Physiotherapie Praxis Tim Winthagen'/>
        <meta property='og:type' content='Website'/>
        <meta property='og:description' content='Homepage der Physiotherapie Praxis Tim Winthagen mit Infos rund um Ihre Behandlung'/>
        <meta property='og:image' content=''/>
        <meta property='og:locale' content='de'/>
        <meta property='og:url' content='https://www.physiotherapie-winthagen.de'/>
        <link rel="canonical" href="https://www.physiotherapie-winthagen.de"/>
      </Helmet>

      <Layout>
          <Header />
          <Mission />
          <Info />
          <LeistungenTeaser short={true}></LeistungenTeaser>
      </Layout>
    </>  
  )
}

export default IndexPage