import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './Mission.module.css'

function Mission() {
    const data = useStaticQuery(graphql`
        query {
            configJson {
                mission {
                    body
                    head
                    quote
                }
            }
        }
    `)

    return (
        <div className={styles.missionContainer}>
            <div className={styles.missionWrapper}>
                <h1 className={styles.missionHead}>{data.configJson.mission.head}</h1>
                <p className={styles.missionQuote}>{data.configJson.mission.quote}</p>
                <p className={styles.missionBody}>{data.configJson.mission.body}</p>
            </div>
        </div>
    )
}

export default Mission
