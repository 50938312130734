import React from 'react'
import * as styles from './Header.module.css'
import logo from "../images/logo_typo.svg"

export default function Header() {
    return (
        <div className={styles.headerImage}>
            <img className={styles.logo} src={logo} alt="praxis logo"/>
        </div>
    )
}
